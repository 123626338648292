var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import find from 'lodash/find';
import get from "lodash/get";
// Takes a location list { locationKey: locationName }
// and turns it into [{ key: locationKey, name: locationName }]
export var locationsToArray = function (locationsArray, list) {
    for (var key in list) {
        // add list.data(?)
        var exists = find(locationsArray, { key: key });
        if (!exists) {
            locationsArray.push({ key: key, name: list[key] });
        }
    }
    return locationsArray;
};
export var sortLocations = function (locations, sortKey) {
    if (sortKey === void 0) { sortKey = "location"; }
    if (!Array.isArray(locations))
        return [];
    var clonedLocations = __spreadArray([], locations, true);
    clonedLocations.sort(function (a, b) {
        var aSortValue = get(a, sortKey, "") || "";
        var bSortValue = get(b, sortKey, "") || "";
        return aSortValue.toLowerCase() < bSortValue.toLowerCase() ? -1 : 1;
    });
    clonedLocations.sort(function (a, b) {
        return get(a, "distance", 0) < get(b, "distance", 0) ? -1 : 1;
    });
    return clonedLocations;
};
